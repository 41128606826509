import { APIProxyBaseURL, CaptivePortal, CaptivePortalDefaults } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import type { GetCaptivePortalQuery as GetCaptivePortalQueryQuery } from '../gql/graphql';
import { graphql } from '../gql';

type RouteParams = {
  uuid: string;
};

const GetCaptivePortalQuery = graphql(`
  query GetCaptivePortal($uuid: UUID!) {
    captivePortal(uuid: $uuid) {
      UUID
      callToAction
      displaySettings {
        backgroundColor
        fontColor
        callToActionColor
      }
      logoImageBase64 {
        data
        imageType
      }
      termsAndConditions
    }
  }
`);

type QueryData = Omit<GetCaptivePortalQueryQuery['captivePortal'], '__typename'>;
const dummyData: QueryData = {
  UUID: '',
  displaySettings: {
    backgroundColor: '#FFFFFF',
    fontColor: '#343647',
    callToActionColor: '#343647',
  },
  callToAction: `Connect to Meter's WiFi`,
  termsAndConditions: `Dolore legimus ius te, ea falli iriure vim, ad sea zril primis gubergren. Ea has quem graece persius, qui ut iisque latine. Mea at nostrud definiebas, atomorum similique ex per. Tale feugait molestiae ea has.

Ex eruditi alienum usu, at erant voluptua repudiare has. Ne pro alia explicari forensibus, wisi mundi doming mel ea. Autem percipit eam id, te eum expetenda definitionem. Sed saepe vocibus commune cu, mei no viderer theophrastus, ei mel affert definitiones.

Mundi suavitate ad cum. Sint placerat sed ex, has nisl animal aperiri ut, harum nominavi quo at. Ei dolorum habemus pri. Nam ad antiopam urbanitas dissentiunt, eos an atomorum vituperata referrentur.

Ex ius nihil ocurreret omittantur, congue adipiscing ne usu. Pro odio pertinax te, est vidit tritani menandri ei, in aliquid inimicus pri. No sed dicat adversarium neglegentur, in enim recteque argumentum cum, in tacimates salutatus persecuti sea. Affert iisque noluisse et eam, vis an quis doming option. Cu has nibh legimus appareat, quo et dicam vidisse vocibus.

Ea dicant mnesarchum definiebas nec, an nulla nihil maluisset his. Utroque nonumes te ius. Quas melius propriae ad per, et sanctus pericula ius, at mei alii minim. Labitur dignissim eos no.`,
};

export default function Portal() {
  const { uuid } = useParams<RouteParams>();
  const [searchParams] = useSearchParams();
  const useDummyData = searchParams.get('use-dummy-data') === '1';
  const captivePortal = useGraphQL(
    GetCaptivePortalQuery,
    { uuid: uuid! },
    {
      enabled: !!uuid && !useDummyData,
    },
  );
  const data = useMemo<QueryData>(() => {
    if (useDummyData) {
      return {
        ...dummyData,
        UUID: uuid!,
      };
    }
    const { captivePortal: cp } = captivePortal.data ?? {};
    if (cp) {
      return {
        ...cp,
        logoImageURL: CaptivePortalDefaults.logoImageURL,
        displaySettings: {
          backgroundColor:
            cp.displaySettings?.backgroundColor ??
            CaptivePortalDefaults.displaySettings.backgroundColor,
          fontColor:
            cp.displaySettings?.fontColor ?? CaptivePortalDefaults.displaySettings.fontColor,
          callToActionColor:
            cp.displaySettings?.callToActionColor ??
            CaptivePortalDefaults.displaySettings.callToActionColor,
        },
      };
    }
    return {
      ...CaptivePortalDefaults,
      UUID: uuid!,
    };
  }, [captivePortal.data, useDummyData, uuid]);

  const confirmLink = useMemo(() => {
    // /v2/captive-portals/:uuid/ack?signature=
    const url = new URL(`${APIProxyBaseURL}/v2/captive-portals/${uuid}/ack`);
    url.search = new URLSearchParams({
      signature: searchParams.get('signature') ?? '',
      ip: searchParams.get('ip') ?? '',
      mac: searchParams.get('mac') ?? '',
      date: searchParams.get('date') ?? '',
    }).toString();
    return url.toString();
  }, [searchParams, uuid]);

  return <CaptivePortal relation="standalone" captivePortal={data} confirmLink={confirmLink} />;
}
