import '@meterup/atto/src/styles/fonts.css';

import { Body, Large, Link, Skeleton, space, VerticalSplit, VStack } from '@meterup/atto';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Portal from './pages/Portal';

function StackedSkeletons({ height = 120 }: { height?: number }) {
  return (
    <VStack spacing={space(16)}>
      <Skeleton height={height} width="100%" radius={6} />
      <Skeleton height={height} width="100%" radius={6} />
      <Skeleton height={height} width="100%" radius={6} />
    </VStack>
  );
}

function Loading() {
  return <VerticalSplit start={<StackedSkeletons />} end={<StackedSkeletons />} footer={null} />;
}

function DefaultPage() {
  return (
    <VerticalSplit
      start={
        <VStack spacing={space(8)}>
          <Large weight="bold">Not found</Large>
          <Body>
            Please try again or contact{' '}
            <Link href="mailto:support@meter.com" target="_blank" rel="noreferrer">
              Meter Support
            </Link>
            .
          </Body>
        </VStack>
      }
      end={null}
      footer={null}
    />
  );
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: import.meta.env.REALM === 'local' ? 'always' : undefined,
      retry: 3,
      useErrorBoundary: true,
      suspense: true,
    },
    mutations: {
      networkMode: import.meta.env.REALM === 'local' ? 'always' : undefined,
    },
  },
});

const root = createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <Suspense fallback={<Loading />}>
      <ErrorBoundary FallbackComponent={DefaultPage}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Routes>
              <Route path="/:uuid" element={<Portal />} errorElement={<DefaultPage />} />
              <Route path="/*" element={<DefaultPage />} />
            </Routes>
          </BrowserRouter>
        </QueryClientProvider>
      </ErrorBoundary>
    </Suspense>
  </React.StrictMode>,
);
